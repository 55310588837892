import * as React from "react";

import { GlobalStyles } from "../styles/GlobalStyles";

const GalleryPage = () => {
  return (
    <>
      <GlobalStyles />
      <h1>Our Gallery!!</h1>
    </>
  );
};

export default GalleryPage;
